<template>
  <v-card v-if="statList">
    <v-card-title>Global Stats</v-card-title>
    <v-row justify="space-around">
      <v-col
        v-for="stat in statList"
        :key="stat.metric"
        cols="6"
        sm="2"
        class="statistics"
      >
        <div class="stat-value text-h6 text-md-h4">
          <animated-integer
            :value="stat.value"
            :animate-on-load="true"
          />
        </div>
        <div class="stat-label text-caption">
          {{ stat.metric }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import AnimatedInteger from '@/components/AnimatedInteger.vue'

export default {
  components: {
    AnimatedInteger,
  },
  computed: {
    ...mapState({
      stats: state => state.stats.all,
    }),
    statList() {
      if (this.stats) {
        return [
          { metric: 'Quests Available', value: this.stats.num_quests },
          { metric: 'Completed Quests', value: this.stats.num_completions },
          { metric: 'Active Participants', value: this.stats.num_active_participants },
          { metric: 'Registered Members', value: this.stats.num_profiles },
          { metric: 'Points Earned', value: this.stats.points_earned },
        ]
      }
      return null
    },
  },
}
</script>
